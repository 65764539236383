import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import { green } from "@material-ui/core/colors"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import Box from "@material-ui/core/Box"
import CardMedia from "@material-ui/core/CardMedia"
import { GatsbyImage } from "gatsby-plugin-image"

const useStyles = makeStyles((theme) => ({
  subscribeBox: {
    padding: theme.spacing(4, 4),
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
    position: "relative",
  },
  successMessage: {
    color: green[500],
    verticalAlign: "middle",
    display: "inline-flex",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  recipePreviewCard: {
    marginBottom: theme.spacing(4),
    border: "1px solid #ecebea",
    boxShadow: "1px 3px 2px rgba(0, 0, 0, 0.05)",
    transition: "all 0.4s ease 0s",
    "&:hover": {
      transform: "translateY(-5px) scale3d(1.01, 1.01, 1.01)",
    },
  },
  flexContainer: {
    display: "flex",
    alignItems: "stretch",
    flexWrap: "wrap",
  },
  recipePreviewImage: {
    flexGrow: 1,
    flexBasis: 0.4 * theme.breakpoints.values.sm,
  },
  recipePreviewContent: {
    flexGrow: 2,
    flexBasis: 0.6 * theme.breakpoints.values.sm,
  },
  insightPreviewHeading: {
    color: "#BE8F63",
    marginTop: 0,
    marginBottom: 0,
    fontSize: "1.15rem",
  },
}))

const InsightsPage = ({ data, location }) => {
  const classes = useStyles()
  const insights = data.allContentfulInsight.edges

  return (
    <Layout>
      <Seo title="Insights" />
      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom>
          All Insights
        </Typography>
        {insights.map(({ node }) => {
          return (
            <Card key={node.id} className={classes.recipePreviewCard}>
              <CardActionArea
                className={classes.flexContainer}
                href={`/insight/${node.slug}/`}
              >
                <CardMedia className={classes.recipePreviewImage}>
                  <GatsbyImage
                    image={node.thumbnailImage.gatsbyImageData}
                    alt=""
                  />
                </CardMedia>

                <CardContent className={classes.recipePreviewContent}>
                  <Typography
                    variant="h4"
                    className={classes.insightPreviewHeading}
                  >
                    {node.title}
                  </Typography>

                  <Box mb={2}>
                    <Typography variant="caption" gutterBottom>
                      {node.createdAt}
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          )
        })}
      </Container>
    </Layout>
  )
}

export default InsightsPage

export const pageQuery = graphql`
  query InsightsQuery {
    allContentfulInsight(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          createdAt(formatString: "MMMM Do, YYYY")
          thumbnailImage {
            gatsbyImageData
          }
        }
      }
    }
  }
`
